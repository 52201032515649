import adminLayout from '@/layouts/Admin'
import currentRouteMixin from '@/mixins/current-route.js'
import httpAxios from '@/utils/http-axios.js'
import previewFileAudioComponent from '@/components/PreviewFileAudio'

export default {
    name: 'PuzzlesView',
    data() {
        return {
            puzzle: {},
            currentPuzzleId: null,
            fileCoverImage: null,
            fileImage: null,
            fileAudio: null
        }
    },
    mixins: [currentRouteMixin],
    components: {
        adminLayout,
        previewFileAudioComponent
    },
    mounted() {
        this.currentPuzzleId = this.currentRoute().params.id

        this.getPuzzle()
    },
    methods: {
        getPuzzle() {
            const self = this
            const selects = '?select=name,cover_image,image,audio'

            httpAxios({
                url: self.$backendUrl + '/api/v1/puzzles/' + self.currentPuzzleId + selects,
                method: 'GET'
            }).then(function(response) {
                self.puzzle = response.data.puzzle
            })
        },
        deletePuzzle() {
            const self = this

            httpAxios({
                url: self.$backendUrl + '/api/v1/puzzles/' + self.currentPuzzleId,
                method: 'DELETE'
            }).then(function() {
                self.$router.push({ name: 'admin.puzzles' })
            })
        },
        updatePuzzle() {
            const self = this
            let formData = new FormData()

            formData.append('_method', 'PUT')
            if(self.puzzle.name) formData.append('name', self.puzzle.name)
            if(self.fileCoverImage) formData.append('cover_image', self.fileCoverImage)
            if(self.fileImage) formData.append('image', self.fileImage)
            if(self.fileAudio) formData.append('audio', self.fileAudio)
            
            httpAxios({
                url: self.$backendUrl + '/api/v1/puzzles/' + self.currentPuzzleId,
                method: 'POST',
                data: formData
            }).then(function(response) {
                self.puzzle = response.data.puzzle

                self.notifySuccess()
            })
        },
        handleFileCoverImageUpload(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileCoverImage = null
            }

            this.fileCoverImage = files[0]
        },
        handleFileImageUpload(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileImage = null
            }

            this.fileImage = files[0]
        },
        handleFileAudioUpload(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileAudio = null
            }

            this.fileAudio = files[0]
        }
    }
}